import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import sponsors from 'data/sponsors.json';

const PromoNumbers = () => {
  const { t } = useTranslation();
  const mock = [
    {
      title: t('attendees'),
      number: process.env.REACT_APP_ATTENDEES_NUMBER,
    },
    {
      title: t('team'),
      number: process.env.REACT_APP_TEAMS_NUMBER,
    },
    {
      title: t('km'),
      number: process.env.REACT_APP_KM,
    },
    {
      title: t('sponsoring'),
      number: sponsors.length,
    },
    {
      title: t('photographes'),
      number: process.env.REACT_APP_PHOTOGRAPHES_NUMBER,
    },
    {
      title: t('volunteer'),
      number: process.env.REACT_APP_VOLUNTEER_NUMBER,
    },
  ];
  return (
    <Grid
      container
      justifyContent="center"
      columns={{ xs: 3, sm: 6 }}
      spacing={2}
    >
      {mock.map((item, i) => (
        <Grid
          item
          key={i}
          display="flex"
          alignItems="center"
          flexDirection="column"
          flex="0 0 auto"
          xs={1}
        >
          <Typography variant="h4" sx={{ fontWeight: 700 }} gutterBottom>
            {item.number}
          </Typography>
          <Typography fontSize={{ xs: 14, sm: 16 }}>{item.title}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default PromoNumbers;
