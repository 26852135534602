import Link from '@mui/material/Link';
import PropTypes from 'prop-types';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Stack, Typography } from '@mui/material';

const SocialNetworks = ({ colorInvert = false, justifyContent = 'flex-end' }) => {
  return (
    <Stack direction="row" justifyContent={justifyContent} alignItems="center" >
      <Stack marginRight={{ xs: 1, sm: 2 }}>
        <Typography color={colorInvert ? 'common.white' : 'text.primary'}>Nous suivre</Typography>
      </Stack>
      <Stack marginRight={{ xs: 1, sm: 2 }}>
        <Link
          underline="none"
          href={process.env.REACT_APP_INSTAGRAM}
          target="_blank"
          color={colorInvert ? 'common.white' : 'text.primary'}
          display="flex"
        >
          <InstagramIcon />
        </Link>
      </Stack>
      <Stack marginRight={{ xs: 1, sm: 2 }}>
        <Link
          underline="none"
          href={process.env.REACT_APP_FACEBOOK}
          target="_blank"
          color={colorInvert ? 'common.white' : 'text.primary'}
          display="flex"
        >
          <FacebookIcon />
        </Link>
      </Stack>
    </Stack>
  );
};

SocialNetworks.propTypes = {
  colorInvert: PropTypes.bool,
  justifyContent: PropTypes.string,
};

export default SocialNetworks;
