import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import BaskCountry from 'BaskCountry/BaskCountry';
import Container from 'components/Container';
import Main from 'layouts/Main';
import {
  Contact,
  Hero,
  SponsorsCarousel,
  Poster,
  PromoNumbers,
} from './components';
import Presentation from 'Presentation';
import PicturesSwimRun from 'PicturesSwimRun';

const Home = () => {
  return (
    <Main colorInvert={true}>
      {false && <Poster />}
      {true && <Hero url="sjdltest.JPG" />}
      <Container>
        <Presentation />
      </Container>
      <PicturesSwimRun />
      <Box bgcolor={'alternate.main'}>
        <Container>
          <BaskCountry />
        </Container>
      </Box>
      <Container>
        <PromoNumbers />
      </Container>
      <Divider />
      <Box bgcolor={'primary.main'}>
        <Container paddingX={'0 !important'} maxWidth={1}>
          <SponsorsCarousel />
        </Container>
      </Box>
      <Container>
        <Contact />
      </Container>
    </Main>
  );
};

export default Home;
