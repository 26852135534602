import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Headline = ({ title, subtitle }) => {
  return (
    <Box
      minHeight={500}
      height={'auto'}
      position={'relative'}
      sx={{
        //backgroundColor: theme.palette.alternate.main,
        background: 'url(photo_facebook.png) no-repeat center',
        backgroundSize: 'cover',
        marginTop: -13,
        paddingTop: 13,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          zIndex: 1,
          opacity: '0.8',
        }}
      />
      <Container position={'relative'} zIndex={2}>
        <Stack
          justifyContent="center"
          height="100%"
          alignItems="center"
          justifyItems="center"
        >
          <Stack
            height="100%"
            marginTop={10}
            justifyContent="center"
            alignItems="center"
            maxWidth={500}
          >
            <Typography
              variant="h2"
              align={'center'}
              sx={{
                fontWeight: 700,
                fontSize: 40,
                color: 'common.white',
              }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Box>
                <Typography
                  variant="h6"
                  align={'center'}
                  sx={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: 'common.white',
                  }}
                >
                  {subtitle}
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Headline;
