import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NavItem = ({ title, items, disabled = false }) => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    console.log(window?.location?.pathname);
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const hasActiveLink = () => items.find((i) => i.href === activeLink);
  let titleColor = () => {
    if (disabled) {
      return 'text.secondary';
    }
    if (hasActiveLink()) {
      return 'primary';
    }
    return 'text.primary';
  };
  let linkColor = (p) => {
    let color =
      activeLink === p.href
        ? theme.palette.primary.main
        : theme.palette.text.primary;
    if (disabled) {
      color = 'text.secondary';
    }
    return color;
  };

  const onItemClick = (item) => {
    if (item.disabled === true) {
      return;
    }
    if (item.external) {
      window.open(item.href, { target: '_blank' });
      return;
    }
    navigate(item.href);
  };

  const accordionTitle = (
    <AccordionSummary
      expandIcon={items.length > 1 ? <ExpandMoreIcon /> : <></>}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{ padding: 0 }}
    >
      <Typography fontWeight={hasActiveLink() ? 600 : 400} color={titleColor()}>
        {items.length === 1 && (
          <Link
            underline="none"
            href={items[0].disabled !== true ? items[0].href : '#'}
            color={titleColor()}
          >
            {title}
          </Link>
        )}
        {items.length > 1 && <>{title}</>}
      </Typography>
    </AccordionSummary>
  );
  const { t } = useTranslation();
  return (
    <Box>
      <Accordion
        disableGutters
        elevation={0}
        sx={{ backgroundColor: 'transparent' }}
      >
        {disabled && (
          <Tooltip title={t('commingSoon')}>{accordionTitle}</Tooltip>
        )}
        {!disabled && <>{accordionTitle}</>}

        {items.length > 1 && (
          <AccordionDetails sx={{ padding: 0 }}>
            <Grid container spacing={1}>
              {items.map((p, i) => (
                <Grid item key={i} xs={12}>
                  <Button
                    size={'large'}
                    onClick={() => onItemClick(p)}
                    fullWidth
                    sx={{
                      justifyContent: 'flex-start',
                      color: linkColor(p),
                      backgroundColor:
                        activeLink === p.href
                          ? alpha(theme.palette.primary.main, 0.1)
                          : 'transparent',
                      fontWeight: activeLink === p.href ? 600 : 400,
                    }}
                  >
                    {p.title}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

NavItem.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default NavItem;
