import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Logo from 'Logo/Logo';
import { useTranslation } from 'react-i18next';

import SocialNetworks from 'components/SocialNetworks';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Logo colorInvert={false} />
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            <Box marginTop={1} marginRight={2}>
              <Link color="text.primary" underline="hover" variant={'subtitle2'} href={`mailto:${process.env.REACT_APP_EMAIL}`}>
                {process.env.REACT_APP_EMAIL}
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/"
                color="text.primary"
                variant={'subtitle2'}
              >
                {t('home')}
              </Link>
            </Box>
            <Box marginTop={1}>
              <Button
                variant="outlined"
                color="primary"
                component="a"
                target="blank"
                href={process.env.REACT_APP_ENROLL_LINK}
                size="small"
              >
                {t('enroll')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SocialNetworks justifyContent="center"/>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          marginTop={1}
          gutterBottom
        >
          &copy; SwimRunBask. 2023. {t('all rights reserved')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
