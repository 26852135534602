import CommonHeadline from 'components/Headline/Headline';
import { useTranslation } from 'react-i18next';

const Headline = () => {
  const { t } = useTranslation();
  return (
    <CommonHeadline title={t('sponsors')} subtitle={t('sponsorsDescription')} />
  );
};

export default Headline;
