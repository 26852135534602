import Box from '@mui/material/Box';

const Headline = () => {
  return (
    <Box
      minHeight={500}
      height={'auto'}
      position={'relative'}
      sx={{
        //backgroundColor: theme.palette.alternate.main,
        background: 'url(photo_facebook.png) no-repeat center',
        backgroundSize: 'cover',
        marginTop: -13,
        paddingTop: 13,
      }}
    />
  );
};

export default Headline;
