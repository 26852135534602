import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NavItem = ({
  title,
  id,
  items,
  colorInvert = false,
  disabled = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handleClick = (event, popoverId) => {
    if (items.length === 1) {
      if (items[0].disabled === true) {
        return;
      }
      if (items[0].external) {
        window.open(items[0].href, { target: '_blank' });
        return;
      }
      navigate(items[0].href);
      return;
    }
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const { t } = useTranslation();

  const hasActiveLink = () => items.find((i) => i.href === activeLink);
  let linkColor = colorInvert ? 'common.white' : 'text.primary';
  if (disabled) {
    linkColor = 'text.secondary';
  }

  const onItemClick = (item) => {
    if (item.disabled) {
      return;
    }
    navigate(item.href);
  };

  const item = (
    <Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        aria-describedby={id}
        sx={{ cursor: disabled ? '' : 'pointer' }}
        onClick={(e) => handleClick(e, id)}
      >
        <Typography
          fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
          color={linkColor}
        >
          {title}
        </Typography>
        {items.length > 1 && (
          <ExpandMoreIcon
            sx={{
              marginLeft: theme.spacing(1 / 4),
              width: 16,
              height: 16,
              transform: openedPopoverId === id ? 'rotate(180deg)' : 'none',
              color: linkColor,
            }}
          />
        )}
      </Box>
      {items.length > 1 && (
        <Popover
          elevation={3}
          id={id}
          open={openedPopoverId === id}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            '.MuiPaper-root': {
              maxWidth: items.length > 12 ? 350 : 250,
              padding: 2,
              marginTop: 2,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8,
              borderTop: `3px solid ${theme.palette.primary.main}`,
            },
          }}
        >
          <Grid container spacing={0.5}>
            {items.map((p, i) => (
              <Grid item key={i} xs={items.length > 12 ? 6 : 12}>
                <Button
                  onClick={() => onItemClick(p)}
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    color:
                      activeLink === p.href
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    backgroundColor:
                      activeLink === p.href
                        ? alpha(theme.palette.primary.main, 0.1)
                        : 'transparent',
                    fontWeight: activeLink === p.href ? 600 : 400,
                  }}
                >
                  {p.title}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Popover>
      )}
    </Box>
  );
  if (disabled) {
    return <Tooltip title={t('commingSoon')}>{item}</Tooltip>;
  }
  return item;
};

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  colorInvert: PropTypes.bool,
};

export default NavItem;
