import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

export default function Logo({ colorInvert }) {
  return (
    <Box display={'flex'}>
      <Link
        display="flex"
        component="a"
        href="/"
        title="Swim Run Bask"
        underline="none"
        color={colorInvert ? 'common.white' : 'text.primary'}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            borderRadius="70%"
            component={'img'}
            src={'./logo.png'}
            width="40px"
            height={1}
          />
          <Typography>Swim Run Bask</Typography>
        </Stack>
      </Link>
    </Box>
  );
}
