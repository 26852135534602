import { useTranslation } from 'react-i18next';

const usePages = () => {
  const { t } = useTranslation();
  return {
    informations: [
      {
        title: t('program'),
        href: '/program',
      },
      {
        title: t('raceInformations'),
        href: '/race',
      },
      {
        title: t('sleepAndPark'),
        href: '/information',
      },
      // {
      //   title: t('becomeASponsor'),
      //   href: '/sponsor',
      // },
    ],
    sponsoring: [
      {
        title: t('sponsor'),
        href: '/sponsor',
      },
    ],
    shop: [
      {
        title: t('shop'),
        href: '/shop',
        disabled: true,
      },
    ],
    results: [
      {
        title: t('results'),
        href: process.env.REACT_APP_RESULTS_LINK,
        external: true
      },
    ],
    pictures: [
      {
        title: t('pictures'),
        href: '/picutres',
        disabled: true,
      },
    ],
  };
};
export default usePages;
