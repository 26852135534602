import CommonHeadline from 'components/Headline/Headline';
import { useTranslation } from 'react-i18next';
const Headline = () => {
  const { t } = useTranslation();
  return (
    <CommonHeadline
      title={t('informations')}
      subtitle={t('informationsDescription')}
    />
  );
};

export default Headline;
