import { Box, Link, Paper, Stack, useMediaQuery } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import sponsors from 'data/sponsors.json';
import { useTheme } from '@mui/material/styles';

function SponsorLogo({ logo, name }) {
  return (
    <Stack padding={5}>
      <ImageListItem key={logo}>
        <Box
          component={'img'}
          width="280px"
          src={`./${logo}`}
          alt={name}
          loading="lazy"
        />
      </ImageListItem>
    </Stack>
  );
}
export default function SponsorList() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  let columns = 1;
  if (isXs) {
    columns = 1;
  }
  if (isSm) {
    columns = 3;
  }
  if (isMd) {
    columns = 3;
  }
  if (isLg) {
    columns = 3;
  }
  return (
    <ImageList cols={columns}>
      {sponsors.map((sponsor) => (
        <Paper sx={{ cursor: 'pointer' }} key={sponsor.name}>
          <Stack alignItems="center" justifyContent={'center'} height="100%">
            {sponsor.website && sponsor.website.length > 0 && (
              <Link
                href={
                  sponsor.website && sponsor.website.length > 0
                    ? sponsor.website
                    : ''
                }
                component="a"
                target="_blank"
                rel="noopener"
              >
                <SponsorLogo logo={sponsor.logo} name={sponsor.name} />
              </Link>
            )}
            {(!sponsor.website || sponsor.website.length === 0) && (
              <SponsorLogo logo={sponsor.logo} name={sponsor.name} />
            )}
          </Stack>
        </Paper>
      ))}
    </ImageList>
  );
}
