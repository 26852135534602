import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CampaignIcon from '@mui/icons-material/Campaign';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Rules from '../Rules/Rules';
import { Grid } from '@mui/material';

export default function ProgramTimeline() {
  const { t } = useTranslation();
  const ProgramTimeLineItem = ({ hour, title, icon, description, color }) => {
    return (
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0', fontSize: '18px' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          {hour}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color={color}>{icon}</TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </TimelineContent>
      </TimelineItem>
    );
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={2}>
          <Typography
            variant="h4"
            align={'center'}
            data-aos={'fade-up'}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {t('programDayOne')}
          </Typography>
          <Timeline position="alternate">
            <ProgramTimeLineItem
              hour={t('briefingTime')}
              title={t('briefing')}
              description={t('briefingDescription')}
              icon={<CampaignIcon />}
            />
            {/* <ProgramTimeLineItem
              hour={t('trainingTime')}
              title={t('training')}
              color="secondary"
              description={t('trainingDescription')}
              icon={<PeopleIcon />}
            /> */}
            <ProgramTimeLineItem
              hour={t('racingTime')}
              title={t('racing')}
              description={t('racingDescription')}
              color="secondary"
              icon={<DirectionsRunIcon />}
            />
            <ProgramTimeLineItem
              hour={t('awardsTime')}
              title={t('awards')}
              color="secondary"
              description={t('awardsDescription')}
              icon={<EmojiEventsIcon />}
            />
          </Timeline>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Rules />
      </Grid>
    </Grid>
  );
}
