import { Grid, Stack, Typography } from '@mui/material';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
function BibItem({ logo, title }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {logo}
      <Typography>{title}</Typography>
    </Stack>
  );
}

function BibDetails() {
  const { t } = useTranslation();
  const items = [
    {
      date: 'Le vendredi 22 et samedi 23 septembre 2023',
      who: 'Au Belharra Watermen Club',
      location: '45, Boulevard Thiers – 64500 Saint-Jean de Luz',
      hours: 'De 17h à 20h',
    },
    {
      date: 'Le dimanche 24 septembre 2023',
      who: 'Au Belharra Watermen Club',
      location: '45, Boulevard Thiers – 64500 Saint-Jean de Luz',
      hours: 'De 6h45 à 7h15',
    },
  ];
  return (
    <Stack spacing={4} justifyContent="center">
      <Stack alignItems="center">
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          alignItems="center"
          sx={{
            fontWeight: 700,
          }}
        >
          {t('bibDetails.title')}
        </Typography>
        <Typography alignItems="center">
          {t('bibDetails.description', { number: items.length })}
        </Typography>
      </Stack>
      <Grid container spacing={4}>
        {items.map((item) => (
          <Grid item xs={12} sm={6}>
            <BibItem logo={<InsertInvitationIcon />} title={item.date} />
            <BibItem logo={<WatchLaterIcon />} title={item.hours} />
            <BibItem logo={<PersonIcon />} title={item.who} />
            <BibItem logo={<LocationOnIcon />} title={item.location} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default BibDetails;
