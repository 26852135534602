/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PoolIcon from '@mui/icons-material/Pool';
import EuroIcon from '@mui/icons-material/Euro';
import GroupIcon from '@mui/icons-material/Group';
import { useTranslation } from 'react-i18next';
import race from 'data/race.json';

// REACT_APP_SOLO_EARLY_BIRD_PRICE=35
// REACT_APP_TEAM_EARLY_BIRD_PRICE=75
// REACT_APP_SOLO_PRICE=45
// REACT_APP_TEAM_PRICE=85

function useListItems() {
  const { t } = useTranslation();
  return [
    {
      title: `${process.env.REACT_APP_RUN_KM}km`,
      subtitle: t('presentation.run', {
        number: race.filter((elt) => elt.type === 'run').length,
        total: process.env.REACT_APP_RUN_KM,
      }),
      icon: <DirectionsRunIcon fontSize="large" />,
    },
    {
      title: `${process.env.REACT_APP_SWIM_KM}km`,
      subtitle: t('presentation.swim', {
        number: race.filter((elt) => elt.type === 'swim').length,
        total: 2500,
      }),

      icon: <PoolIcon fontSize="large" />,
    },
    {
      title: t('presentation.participationTitle'),
      subtitle: t('presentation.participation'),
      icon: <GroupIcon fontSize="large" />,
    },
    {
      title: `${process.env.REACT_APP_TEAM_EARLY_BIRD_PRICE}€ ou ${process.env.REACT_APP_SOLO_EARLY_BIRD_PRICE}€`,
      subtitle: t('presentation.price', {
        priceEarlyBirdSolo: process.env.REACT_APP_SOLO_EARLY_BIRD_PRICE,
        priceEarlyBirdTeam: process.env.REACT_APP_TEAM_EARLY_BIRD_PRICE,
        priceSolo: process.env.REACT_APP_SOLO_PRICE,
        priceTeam: process.env.REACT_APP_TEAM_PRICE,
      }),
      icon: <EuroIcon fontSize="large" />,
    },
  ];
}

const Presentation = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const items = useListItems();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          {t('presentation.title')}
        </Typography>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          {t('presentation.subtitle', { number: process.env.REACT_APP_KM })}
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          {t('presentation.description', { total: process.env.REACT_APP_KM })}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {items.map((item, i) => (
          <Grid key={i} item xs={12} md={3}>
            <ListItem
              component="div"
              disableGutters
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: 0,
              }}
            >
              <Box
                component={ListItemAvatar}
                marginBottom={1}
                minWidth={'auto !important'}
              >
                <Box color={theme.palette.primary.main}>{item.icon}</Box>
              </Box>
              <ListItemText
                primary={item.title}
                secondary={item.subtitle}
                primaryTypographyProps={{
                  variant: 'h6',
                  gutterBottom: true,
                  align: 'left',
                }}
                secondaryTypographyProps={{ align: 'left' }}
                sx={{
                  '& .MuiListItemText-primary': {
                    fontWeight: 700,
                  },
                  margin: 0,
                }}
              />
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Presentation;
