/* eslint-disable indent */
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import EastIcon from '@mui/icons-material/East';
import PoolIcon from '@mui/icons-material/Pool';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { orange } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import raceItems from 'data/race.json';
import { useTranslation } from 'react-i18next';

export default function Illustration() {
  const { t } = useTranslation();
  const theme = useTheme();

  const getLogo = (item) => {
    if (item.type === 'swim') {
      return <PoolIcon color="primary" sx={{ fontSize: 80 }} />;
    }
    return <DirectionsRunIcon sx={{ fontSize: 80, color: orange[900] }} />;
  };
  const getTitle = (item) => {
    let title = '';
    if (item.type === 'swim') {
      title = `NAT ${item.number}`;
    } else {
      title = `CAP ${item.number}`;
    }
    return title;
  };
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  let slidesToShow = 2;
  if (isXs) {
    slidesToShow = 2;
  }
  if (isSm) {
    slidesToShow = 4;
  }
  if (isMd) {
    slidesToShow = 4;
  }
  if (isLg) {
    slidesToShow = 6;
  }
  const raceSlides = [[]];
  let counter = 0;
  let swimNumber = 1;
  let runNumber = 1;
  for (let i = 0; i < raceItems.length; i++) {
    raceSlides[raceSlides.length - 1].push({
      ...raceItems[i],
      number: raceItems[i].type === 'swim' ? swimNumber++ : runNumber++,
    });
    counter++;
    if (counter === slidesToShow - 1 && i < raceItems.length - 1) {
      raceSlides.push([]);
      counter = 0;
    }
  }
  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          {t('race.illustrationTitle')}
        </Typography>
      </Stack>
      {raceSlides.map((raceSlide, raceSlideIndex) => (
        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          justifyContent="center"
        >
          {raceSlide.map((raceItem, index) => (
            <Stack direction="row" maxWidth={300}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
                  {getTitle(raceItem, index)}
                </Typography>
                {getLogo(raceItem)}
                <Typography>
                  {raceSlideIndex === 0 && index === 0 && (
                    <Typography
                      align="center"
                      fontSize={23}
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      Départ
                    </Typography>
                  )}
                </Typography>
                <Typography align="center" variant="body2" fontStyle="italic">
                  {raceItem.description}
                </Typography>
              </Stack>
              <Stack justifyContent="center" alignItems="center">
                <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
                  {raceItem.meters}m
                </Typography>
                <EastIcon sx={{ fontSize: 40 }} />
              </Stack>
              {raceSlideIndex === raceSlides.length - 1 &&
                index === raceSlide.length - 1 && (
                  <Stack
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SportsScoreIcon sx={{ fontSize: 120 }} />
                    <Typography
                      align="center"
                      fontWeight={600}
                      fontSize={23}
                      textTransform="uppercase"
                    >
                      Arrivée
                    </Typography>
                    <Typography align="center" variant="body2">
                      Digue aux cheveaux
                    </Typography>
                  </Stack>
                )}
            </Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  );
}
