import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import Logo from 'Logo/Logo';
import { useTranslation } from 'react-i18next';
import NavItem from './components/NavItem';

const SidebarNav = ({ pages }) => {
  const { informations, shop, results, pictures, sponsoring } = pages;

  const { t } = useTranslation();

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Logo />
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={t('informations')} items={informations} />
        </Box>
        <Box>
          <NavItem title={t('sponsoring')} items={sponsoring} />
        </Box>
        <Box>
          <NavItem title={t('results')} items={results} />
        </Box>
        <Box>
          <NavItem title={t('shop')} items={shop} disabled />
        </Box>
        <Box>
          <NavItem title={t('pictures')} items={pictures} disabled />
        </Box>
        <Box marginTop={1}>
          <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            href={process.env.REACT_APP_ENROLL_LINK}
          >
            {t('enroll')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
