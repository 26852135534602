import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import sponsors from 'data/sponsors.json';
import Carousel from 'react-material-ui-carousel';

const SponsorsCarousel = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  let slidesToShow = 2;
  if (isXs) {
    slidesToShow = 2;
  }
  if (isSm) {
    slidesToShow = 3;
  }
  if (isMd) {
    slidesToShow = 4;
  }
  if (isLg) {
    slidesToShow = 5;
  }
  const sponsorsSlides = [[]];
  let counter = 0;
  for (let i = 0; i < sponsors.length; i++) {
    sponsorsSlides[sponsorsSlides.length - 1].push(sponsors[i]);
    counter++;
    if (counter === slidesToShow - 1 && i < sponsors.length - 1) {
      sponsorsSlides.push([]);
      counter = 0;
    }
  }

  return (
    <Carousel
      navButtonsAlwaysVisible
      indicators={false}
      animation="slide"
      autoPlay
      duration={100}
      cycleNavigation
    >
      {sponsorsSlides.map((slide) => (
        <Stack
          direction="row"
          paddingBottom={3}
          paddingTop={3}
          spacing={4}
          paddingLeft={12}
          paddingRight={12}
          alignItems="center"
          justifyContent="space-around"
        >
          {slide.map((sponsor) => (
            <a href={sponsor.website} target="_blank" rel="noreferrer">
              <Box
                height={120}
                component="img"
                src={`/${sponsor.logo}`}
                alt={`Logo of ${sponsor.name}`}
              />
            </a>
          ))}
        </Stack>
      ))}
    </Carousel>
  );
};

export default SponsorsCarousel;
