import { ImageList, ImageListItem, Box } from '@mui/material';

const PicturesSwimRun = () => {
  const itemDataLg = [
    { img: 'swimrunbeach.jpeg', cols: 1, rows: 3 },
    { img: 'swimrunalone.jpg', cols: 1, rows: 3 },
    { img: 'swimrunjump.jpg', cols: 2, rows: 5 },
    { img: 'test.png', cols: 2, rows: 2 },
  ];
  const itemData = [
    { img: 'swimrunbeach.jpeg', cols: 1, rows: 3 },
    { img: 'swimrunalone.jpg', cols: 1, rows: 3 },
    { img: 'swimrunjump.jpg', cols: 2, rows: 5 },
  ];
  const itemSm = [
    { img: 'swimrunbeach.jpeg', cols: 1, rows: 3 },
    { img: 'swimrunalone.jpg', cols: 1, rows: 3 },
    { img: 'swimrunjump.jpg', cols: 2, rows: 5 },
    { img: 'swim.jpeg', cols: 2, rows: 5 },
  ];
  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
  return (
    <>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <ImageList variant="quilted" cols={1} rowHeight={121}>
          {itemData.map((item) => (
            <ImageListItem key={item.img} cols={1} rows={4}>
              <img
                {...srcset(item.img, 121, 4, 1)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'block', lg: 'none' } }}>
        <ImageList variant="quilted" cols={2} rowHeight={121}>
          {itemSm.map((item) => (
            <ImageListItem key={item.img} cols={1} rows={4}>
              <img
                {...srcset(item.img, 121, 4, 1)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <ImageList variant="quilted" cols={4} rowHeight={121}>
          {itemDataLg.map((item) => (
            <ImageListItem
              key={item.img}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.img, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
};

export default PicturesSwimRun;
