/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import { useTranslation, Trans } from 'react-i18next';

const Contact = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'relative',
        '&::after': {
          position: 'absolute',
          content: '""',
          width: '40%',
          height: '100%',
          zIndex: 1,
          top: 0,
          right: 0,
          backgroundSize: '18px 18px',
          backgroundImage: `radial-gradient(${alpha(
            theme.palette.primary.dark,
            0.4,
          )} 20%, transparent 20%)`,
          opacity: 0.2,
        },
      }}
    >
      <Box position={'relative'} zIndex={2}>
        <Box>
          <Box marginBottom={1}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
              }}
            >
              {t('contactUs')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" color={'text.secondary'}>
              <Trans
                i18nKey="contactUsDescription"
                values={{
                  email: process.env.REACT_APP_EMAIL,
                  facebookLink: process.env.REACT_APP_FACEBOOK,
                  instagramLink: process.env.REACT_APP_INSTAGRAM,
                }}
                components={{ a: <Link underline="hover" color="text.secondary" /> }}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
