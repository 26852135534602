import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Main from 'layouts/Main';
import { Headline } from './components';

export default function InformationDetails() {
  return (
    <Main>
      <Box>
        <Headline />
        <Container maxWidth={800} paddingBottom={'0 !important'}>
          <Stack alignItems="center">
            <Typography
              variant="h4"
              align={'center'}
              data-aos={'fade-up'}
              gutterBottom
              alignItems="center"
              sx={{
                fontWeight: 700,
              }}
            >
              Se garer
            </Typography>
            <Link
              target="_blank"
              href="https://www.saintjeandeluz.fr/fr/vie-quotidienne/mobilite-et-stationnement/le-stationnement-a-saint-jean-de-luz/"
            >
              <Typography>Pour stationner en centre ville</Typography>
            </Link>
            <Link
              target="_blank"
              href="https://www.saintjeandeluz.fr/fr/le-parking-relais-ilargia-et-la-navette-littorale-gratuite-mis-en-service/"
            >
              <Typography>
                Pour stationner avec les parkings relais (navette gratuite)
              </Typography>
            </Link>
          </Stack>
        </Container>
        <Container>
          <Divider />
        </Container>
        <Stack alignItems="center" marginBottom={4}>
          <Typography
            variant="h4"
            align={'center'}
            data-aos={'fade-up'}
            gutterBottom
            alignItems="center"
            sx={{
              fontWeight: 700,
            }}
          >
            Se loger
          </Typography>
          <Link
            target="_blank"
            href="https://all.accor.com/hotel/6553/index.fr.shtml"
          >
            <Typography>Hôtel Ibis (Ciboure)</Typography>
          </Link>
          <Typography>
            Code de réduction: <strong>URKI2023</strong>
          </Typography>
        </Stack>
      </Box>
    </Main>
  );
}
