import Box from '@mui/material/Box';

import Presentation from 'Presentation/Presentation';
import Container from 'components/Container';
import Headline from 'components/Headline/Headline';
import Main from 'layouts/Main';
import { useTranslation } from 'react-i18next';
import { Illustration } from './components';

const RaceDetails = () => {
  const { t } = useTranslation();

  return (
    <Main>
      <Headline title={t('race.title')} />
      <Box bgcolor={'alternate.main'}>
        <Container>
          <Illustration />
        </Container>
      </Box>
      <img height="auto" width={'100%'} src="./race.jpeg" />
      <Box>
        <Container>
          <Presentation />
        </Container>
      </Box>
    </Main>
  );
};

export default RaceDetails;
