import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import Home from './views/Home';
import NotFound from 'views/NotFound';
import HireUs from 'views/ProgramDetails/Program';
import InformationDetails from 'views/InformationDetails';
import SponsorDetails from 'views/SponsorDetails';
import RaceDetails from 'views/RaceDetails/RaceDetails';

const Routes = () => {
  return (
    <ReactRoutes>
      <Route key="home" path="/" element={<Home />} />
      <Route key="program" path="/program" element={<HireUs />} />
      <Route
        key="information"
        path="/information"
        element={<InformationDetails />}
      />
      <Route key="sponsor" path="/sponsor" element={<SponsorDetails />} />
      <Route key="race" path="/race" element={<RaceDetails />} />
      <Route key="notFound" path="/not-found" element={<NotFound />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </ReactRoutes>
  );
};

export default Routes;
