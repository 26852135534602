/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import Container from 'components/Container';
import { useTranslation } from 'react-i18next';

const Hero = ({ url = 'ST-JEAN-DE-LUZ.jpg' }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const GridItemHeadlineBlock = () => (
    <Box marginBottom={4} data-aos="fade-up" textAlign="center">
      <Stack>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: 900,
            color: 'common.white',
          }}
          fontSize={{ xs: 'h4.fontSize', sm: 'h3.fontSize' }}
        >
          {t('title')}
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Typography
            variant="h3"
            sx={{
              fontWeight: 900,
              color: 'common.white',
            }}
            fontSize={{ xs: 'h4.fontSize', sm: 'h3.fontSize' }}
          >
            Saint-Jean-De-Luz
          </Typography>
          {/* <SquareIcon sx={{ color: 'white' }} />
          <Typography
            variant="h3"
            sx={{
              fontWeight: 900,
              color: 'common.white',
            }}
          >
            2023
          </Typography> */}
        </Stack>
        <Typography
          variant="h6"
          component="p"
          color="text.primary"
          sx={{
            fontWeight: 500,
            color: 'common.white',
          }}
          whiteSpace={'pre-line'}
          fontSize={{ xs: 'h8.fontSize', md: 'h7.fontSize', lg: 'h6.fontSize' }}
        >
          {t('description')}
        </Typography>
      </Stack>
      <Stack marginTop={8} justifyContent="center" textAlign="center"></Stack>
    </Box>
  );

  return (
    <Box
      minHeight={750}
      height={'auto'}
      position={'relative'}
      sx={{
        marginTop: -13,
        paddingTop: 13,
        backgroundColor: theme.palette.alternate.main,
        background: `url(${url}) no-repeat center`,
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          backgroundColor: theme.palette.primary.main,
          backgroundImage: `linear-gradient(315deg, ${theme.palette.primary.main} 0%, #000000 64%)`,
          opacity: '0.6',
          zIndex: 1,
        }}
      />
      <Container position={'relative'} zIndex={2}>
        <Stack justifyContent="center" height="100%" alignItems="center">
          <GridItemHeadlineBlock />
        </Stack>
      </Container>
    </Box>
  );
};

export default Hero;
