import GavelIcon from '@mui/icons-material/Gavel';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function Rules() {
  const { t } = useTranslation();
  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          {t('rules')}
        </Typography>
        <Typography variant="body2" align={'center'} data-aos={'fade-up'}>
          {t('rulesDescription')}
        </Typography>
      </Stack>
      <Stack>
        <Button
          variant="contained"
          startIcon={<GavelIcon />}
          target="_blank"
          href={process.env.REACT_APP_RULES_LINK}
          size="large"
        >
          {t('seeRules')}
        </Button>
      </Stack>
    </Stack>
  );
}
