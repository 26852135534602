import Box from '@mui/material/Box';

import Container from 'components/Container';
import Main from 'layouts/Main';
import { Headline, SponsorList } from './components';

export default function SponsorDetails() {
  return (
    <Main>
      <Box>
        <Headline />
        <Box bgcolor={'alternate.main'}>
          <Container>
            <SponsorList />
          </Container>
        </Box>
      </Box>
    </Main>
  );
}
