import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Logo from 'Logo/Logo';
import { NavItem } from './components';
import { useTranslation } from 'react-i18next';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { informations, shop, results, pictures, sponsoring } = pages;

  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Logo colorInvert={colorInvert} />
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box>
          <NavItem
            title={t('informations')}
            id={'informations'}
            items={informations}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={t('sponsoring')}
            id={'sponsoring'}
            items={sponsoring}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={t('results')}
            id={'secondary-pages'}
            items={results}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={t('shop')}
            id={'shop'}
            items={shop}
            colorInvert={colorInvert}
            disabled
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={t('pictures')}
            id={'blog-pages'}
            items={pictures}
            colorInvert={colorInvert}
            disabled
          />
        </Box>
        <Box marginLeft={4}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href={process.env.REACT_APP_ENROLL_LINK}
            size="large"
          >
            {t('enroll')}
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
