import Box from '@mui/material/Box';

import Presentation from 'Presentation/Presentation';
import Container from 'components/Container';
import Main from 'layouts/Main';
import { Headline, ProgramTimeline } from './components';

const Program = () => {
  return (
    <Main>
      <Headline />
      <Box>
        <Container>
          <ProgramTimeline />
        </Container>
        <Box bgcolor={'alternate.main'}>
          <Container>{/* <BibDetails /> */}</Container>
        </Box>
        <Box>
          <Container>
            <Presentation />
          </Container>
        </Box>
      </Box>
    </Main>
  );
};

export default Program;
